@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:300,400,500,600,700&display=swap");
body {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #747e88;
  overflow-x: hidden;
}



label {
  position: relative;
  width: 48px; 
  height: 24px; 
  margin-bottom: 0 !important;
  display: block;
  background: #d9d9d9;
  border-radius: 100px;
  cursor: pointer;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3), inset 0px -1px 3px rgba(255, 255, 255, 0.3); 
}

label:after {
  content: '';
  position: absolute;
  height: 20px; 
  width: 20px; 
  background: #f2f2f2;
  border-radius: 100px;
  top: 2px; 
  left: 2px; 
  transition: 0.5s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); 
}

input:checked ~ label:after {
  left: 45px; 
  transform: translateX(-100%); 
  background: linear-gradient(180deg, #777, #3a3a3a);
}

input:checked ~ label {
  background: #242424;
}

.background {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: -1;
  transition: 0.5s;
}

input:checked + label + .background {
  background: #242424;
}

input {
  display: none;
}

.dark-mode , .dark-mode .preview-box, .dark-mode .select-menu .select-btn ,.dark-mode .select-menu .options, .dark-mode .select-menu .option{
  background-color: black !important;
  color: white !important;
}

.mode{
  height: 50px;
  display: flex;
  align-items: center;
}
body {
  background-color: white;
  color: black;
}
.dark-mode nav .navbar .links  a{
  color: white;
}
.dark-mode .sticky, .dark-mode .nav-links.active {
  color: white; 
  background-color: black ;
}
.light-mode {
  color: #000; 
}

.dark-mode {
  color: #fff; 
}

.dark-mode .lni {
  filter: invert(1); 
}

.features-icon .lni::before {
  color: white;
  font-size:2rem;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #38424d;
  margin: 0px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #747e88;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}


.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid #0070d3;
  padding: 0 15px;
  font-size: 1rem;
  height: 55px;
  line-height: 51px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #0070d3;
}
.main-btn:hover {
  background-color: rgba(8, 152, 231, 0.7);
  color: #fff;
  border-color: transparent;
}
.main-btn.main-btn-2 {
  background-color: transparent;
  color: #525a63;
  border-color: #525a63;
}
.main-btn.main-btn-2:hover {
  background-color: rgba(82, 90, 99, 0.5);
  color: #525a63;
}

@keyframes animation1 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes animation1 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.section-title {
  text-align:start;
}
.section-title .title {
  font-size: 35px;
  font-weight: 600;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title .title {
    font-size: 26px;
  }
}

.section-title .text {
  margin-top: 20px;
}


.slick-slide {
  outline: 0;
}



.loader-wrapper {
  --line-width: 5px;
  --curtain-color: #000000;
  --outer-line-color: #005aff;
  --middle-line-color: #6700d7;
  --inner-line-color: #590025;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1000;
}

.loader {
  display:block;
  position: relative;
  top:50%;
  left:50%;
  width:150px;
  height:150px;
  margin:-75px 0 0 -75px;
  border:var(--line-width) solid transparent;
  border-top-color: var(--outer-line-color);
  border-radius:100%;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  z-index:1001;
}

.loader:before {
  content:"";
  position: absolute;
  top:4px;
  left:4px;
  right:4px;
  bottom:4px;
  border:var(--line-width) solid transparent;
  border-top-color: var(--inner-line-color);
  border-radius:100%;
  -webkit-animation: spin 3s linear infinite;
          animation: spin 3s linear infinite;
}

.loader:after {
  content:"";
  position: absolute;
  top:14px;
  left:14px;
  right:14px;
  bottom:14px;
  border:var(--line-width) solid transparent;
  border-top-color: var(--middle-line-color);
  border-radius:100%;
  -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0%   { 
    -webkit-transform: rotate(0deg); 
      -ms-transform: rotate(0deg); 
          transform: rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(360deg); 
      -ms-transform: rotate(360deg); 
          transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   { 
    -webkit-transform: rotate(0deg); 
      -ms-transform: rotate(0deg); 
          transform: rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(360deg); 
      -ms-transform: rotate(360deg); 
          transform: rotate(360deg);
  }
}

.loader-wrapper .loader-section {
  position:fixed;
  top:0;
  background:var(--curtain-color);
  width:51%;
  height:100%;
  z-index:1000;
}

.loader-wrapper .loader-section.section-left {
  left:0
}
.loader-wrapper .loader-section.section-right {
  right:0;
}

.loaded .loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645,0.045,0.355,1.000);
}
.loaded .loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645,0.045,0.355,1.000);
}
.loaded .loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.loaded .loader-wrapper {
  visibility: hidden;
  transform:translateY(-100%);
  transition: all .3s 1s ease-out;
}



.navbar .lni-menu {
  display: none;
}





.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease-out 0s;
  width:100%;
}

.sticky .navbar {
  padding: 17px 0;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  z-index: 999;
}

nav .navbar {
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8%;
}
.navbar .logo a img {
  max-width: 6rem;
}

.navbar .lni-menu {
  display: none;
}

nav .navbar .nav-links {
  line-height: 70px;
  height: 100%;
}

nav .navbar .links {
  display: flex;
  align-items: center;
}

nav .navbar .links li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 14px;
}

nav .navbar .links li a {
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: black;
  font-size: 15px;
  font-weight: 500;
  position: relative;
}


nav .navbar .links li .link:hover {
  color: #0070d3;
}


.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow {
  transform: rotate(180deg);
}

nav .navbar .links li .arrow {
  height: 100%;
  width: 22px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  color: black;
  transition: all 0.3s ease;
}

nav .navbar .links li .sub-menu {
  position: absolute;
  top: 70px;
  left: 0;
  line-height: 40px;
  background: white;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 2;
  width: 200px;
}

nav .navbar .links li:hover .htmlCss-sub-menu,
nav .navbar .links li:hover .js-sub-menu {
  display: block;
}

.navbar .links li .sub-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar .links li .sub-menu li:hover {
  background-color: #efefef;
}

.navbar .links li .sub-menu a {
  color: black;
  padding-right: 17vh;
  font-size: 15px;
  font-weight: 500;
}

.navbar .links li .sub-menu .more-arrow {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, -30%);
}

.navbar .links li .sub-menu .more-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 1;
  display: none;
}

.links li .sub-menu .more:hover .more-sub-menu {
  display: block;
}

.menu-icon {
  font-size: 30px;
  color: black;
  cursor: pointer;
  display: none;
}

@media only screen and (max-width: 794px) {
  nav .navbar .lni-menu {
      display: block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
  }
  nav .navbar .links {
      display: none;
  }
  .nav-links.active .links {
      display: block;
  }
  nav .navbar .lni-menu {
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
  }
  nav .navbar .links {
      display: none;
  }
  .dark-mode .nav-links.active, .dark-mode .sticky, .dark-mode nav .navbar .lni-menu{
    color: white;
    background-color: black !important;
  }
  .nav-links {
      transition: all 0.5s ease;
  }

  .nav-links.active {
      flex-direction: column;
      width: 70%;
      height: 100vh !important;
      background: white;
      position: absolute;
      top: 74px;
      left: 0;
      box-shadow: 0px 3px 6px 3px rgb(202 201 201 / 35%);;
      border-radius: 0 0 4px 4px;
      padding: 0 23px;
  }

  nav .navbar .nav-links {
      line-height: 60px;
      height: 100%;
  }

  .nav-links.active .links li {
      margin-left: 0;
      border-bottom: 1px solid #8080807a;
      width: 100%;
  }
  nav .navbar .links li .link::after {
      display: none;
  }
  .icon-shape{
    font-size: 6vw !important;
    width: 9vw !important;
    height: 9vw !important;
    border-radius: 2vw !important;
  }
  .header-area{
    margin-bottom:10px;
  }
  .pl-50{
    padding-left: 0 !important;
  }
}

.icon-shape {
  position: absolute;
  font-size: 37px;
  font-weight: 500;
  width: 51px;
  height: 51px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw; 
  box-shadow: 4vw 0 12vw rgba(54, 32, 152, .3); 
  
}

.icon-shape-1 {
  top: 13vw; 
  right: 4vw; 
  background-color: #001631;
  color: cyan;
  -webkit-animation: anim_moveBottom 1.5s linear 0s infinite alternate;
  animation: anim_moveBottom 1.6s linear 0s infinite alternate;
}

.icon-shape-2 {
  top: 6vw; 
  left: 3vw; 
  background-color: #390000;
  color: red;
  -webkit-animation: anim_moveBottom 1.2s linear 0s infinite alternate;
  animation: anim_moveBottom 1.4s linear 0s infinite alternate;
}

.icon-shape-3 {
  bottom: 0; 
  left: 12vw;
  background-color: #fff;
  color: #00b8ff;
  -webkit-animation: anim_moveBottom 2s linear 0s infinite alternate;
  animation: anim_moveBottom 1.8s linear 0s infinite alternate;
}

@-webkit-keyframes anim_moveBottom {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
  }
}

@keyframes anim_moveBottom {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
  }
  100% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
  }
}
section{
  position:relative;
}
.shape-1{
  position: absolute;
  width: 22vw;
  top: 0;
  right: 0;
  z-index: -1;
}
.shape-2{
  position: absolute;
  width: 22vw;
  top: 0;
  left: 0;
  z-index: -1;
}

.s1{
  position: absolute;
  width: 22vw;
  top: 0vw;
  right: 0;
  z-index: -1;
}
.s3{
  position: absolute;
  width: 22vw;
  top: 0vw;
  left: 0;
  z-index: -1;
}
.s4{
  position: absolute;
  width: 22vw;
  top: 0vw;
  right: 0;
  z-index: -1;
}
.s5{
  position: absolute;
  width: 22vw;
  top: 0vw;
  right: 0;
  z-index: -1;
}
.s6{
  position: absolute;
  width: 22vw;
  top:0w;
  right: 0;
  z-index: -1;
}
.s2{
  position: absolute;
  width: 209px;
  top:0px;
  right:0;
  z-index: -1;
}

.header-hero {
  position: relative;
  z-index: 5;
  background-position: bottom center;
  height: 710px;
}
@media only screen and (min-width: 1400px) {
  .header-hero {
    height: 900px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-hero {
    height: auto;
  }
}
@media (max-width: 767px) {
  .header-hero {
    height: auto;
  }
}
.header-hero .shape {
  position: absolute;
}
.header-hero .shape.shape-1 {
  width: 75px;
  height: 75px;
  background: -webkit-linear-gradient(rgb(102 100 254 / 50%) 0%, rgb(110 194 254 / 50%) 100%);
  background: -o-linear-gradient(rgb(102 100 254 / 50%) 0%, rgb(110 194 254 / 50%) 100%);
  background: linear-gradient(rgb(102 100 254 / 50%) 0%, rgb(110 194 254 / 50%) 100%);
  border-radius: 50%;
  left: 130px;
  top: 25%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
  filter: blur(10%);
}

.header-hero .shape.shape-2 {
  width: 39px;
  height: 39px;
  background: -webkit-linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  );
  background: -o-linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  );
  background: linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  );
  left: 150px;
  bottom: 40px;
  border-radius: 50%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-3 {
  width: 19px;
  height: 19px;
  background: -webkit-linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  );
  background: -o-linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  );
  background: linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  );
  bottom: 25%;
  left: 26%;
  border-radius: 50%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-4 {
  background: -webkit-linear-gradient(rgb(69 58 247 / 50%) 0%, rgb(255 90 204 / 50%) 100%);
  background: -o-linear-gradient(rgb(69 58 247 / 50%) 0%, rgb(255 90 204 / 50%) 100%);
  background: linear-gradient(rgb(69 58 247 / 50%) 0%, rgb(255 90 204 / 50%) 100%);
  border-radius: 50%;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  top: 175px;
  left: 40%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-5 {
  width: 19px;
  height: 19px;
  background-color: rgba(108, 99, 255, 0.55);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20%;
  border-radius: 50%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-6 {
  width: 14px;
  height: 14px;
  background-color: rgb(146 6 163 / 55%);
  border-radius: 50%;
  left: 45%;
  bottom: 70px;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}



.header-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-shape-2 img {
    width: 295px;
  }
}

.header-hero-content {
  position: relative;
  text-align: justify;
  z-index: 9;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-hero-content {
    padding-top: 150px;
  }
}
@media (max-width: 767px) {
  .header-hero-content {
    padding-top: 120px;
  }
}
.header-hero-content .header-title {
  font-size: 42px;
  color: #38424d;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-hero-content .header-title {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .header-hero-content .header-title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-hero-content .header-title {
    font-size: 38px;
  }
}
.header-hero-content .header-title span {
  display: contents;
  color: #0070d3;
}
.header-hero-content .text {
  margin-top: 30px;
}
.header-hero-content ul {
  margin-top: 30px;
}
.header-hero-content ul li {
  margin-left: 50px;
}
.header-hero-content ul li:first-child {
  margin-left: 0;
}
.header-hero-content .main-btn {
  margin-top: 10px;
}
.header-hero-content .header-video {
  margin-top: 15px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  border-radius: 50%;
  background-color: #0898e7;
  position: relative;
  z-index: 5;
}
.header-hero-content .header-video::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(8, 152, 231, 0.8);
  top: 0;
  left: 0;
  -webkit-animation: video 2s linear infinite;
  -moz-animation: video 2s linear infinite;
  -o-animation: video 2s linear infinite;
  animation: video 2s linear infinite;
  z-index: -1;
}

@keyframes video {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

@-webkit-keyframes video {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

.header-image {
  padding-top: 0px;
  position: relative;
  z-index: 5;
}
.header-image .image {
  width: 100%;
}
.header-image .image-shape {
  position: absolute;
  bottom: -100px;
  left: -115px;
  z-index: -1;
}
@media (max-width: 767px) {
  .header-image .image-shape {
    left: -90px;
  }
}
.header-image .image-shape img {
  max-width: 350px;
}
@media (max-width: 767px) {
  .header-image .image-shape img {
    max-width: 250px;
  }
}
.single-services {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(154, 154, 154, 0.16);
  -moz-box-shadow: 0px 0px 10px 0px rgba(154, 154, 154, 0.16);
  box-shadow: 0px 0px 10px 0px rgba(154, 154, 154, 0.16);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 45px 25px 35px;
}
@media (max-width: 767px) {
  .single-services {
    padding: 40px 20px 30px;
  }
}
.single-services .services-icon {
  width: 79px;
  height: 79px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  text-align: center;
}
.single-services .services-icon i {
  font-size: 46px;
  line-height: 79px;
  color: #fff;
}
.single-services .services-icon::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  border-radius: 50%;
  z-index: -1;
}
.single-services .services-content {
  margin-top: 35px;
}
.single-services .services-content .services-title a {
  font-size: 25px;
  font-weight: 500;
  color: #222;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .single-services .services-content .services-title a {
    font-size: 20px;
  }
}
.single-services .services-content .text {
  margin-top: 30px;
}
.single-services.services-color-1 .services-icon {
  background-color: #e7a019;
}
.single-services.services-color-1 .services-icon::before {
  background-color: rgba(231, 160, 25, 0.2);
}
.single-services.services-color-1 .services-content .services-title a:hover {
  color: #e7a019;
}
.single-services.services-color-2 .services-icon {
  background-color: #e54f4f;
}
.single-services.services-color-2 .services-icon::before {
  background-color: rgba(229, 79, 79, 0.2);
}
.single-services.services-color-2 .services-content .services-title a:hover {
  color: #e54f4f;
}
.single-services.services-color-3 .services-icon {
  background-color: #8950e4;
}
.single-services.services-color-3 .services-icon::before {
  background-color: rgba(137, 80, 228, 0.2);
}
.single-services.services-color-3 .services-content .services-title a:hover {
  color: #8950e4;
}
.single-services.services-color-4 .services-icon {
  background-color: #0898e7;
}
.single-services.services-color-4 .services-icon::before {
  background-color: rgba(8, 152, 231, 0.2);
}
.single-services.services-color-4 .services-content .services-title a:hover {
  color: #0898e7;
}
.single-services:hover {
  -webkit-box-shadow: 0px 15px 17px 0px rgba(154, 154, 154, 0.16);
  -moz-box-shadow: 0px 15px 17px 0px rgba(154, 154, 154, 0.16);
  box-shadow: 0px 15px 17px 0px rgba(154, 154, 154, 0.16);
}


.about-image {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-left: 50px;
  padding-bottom: 100px;
}
@media (max-width: 767px) {
  .about-image {
    margin-left: 0;
    padding-top: 65px;
    padding-bottom: 45px;
  }
}
.about-image .about-shape {
  background: -webkit-linear-gradient(#61009d 0%, #cb107a 100%);
  background: -o-linear-gradient(#61009d 0%, #cb107a 100%);
  background: linear-gradient(#61009d 0%, #cb107a 100%);
  width: 394px;
  height: 394px;
  border-radius: 50%;
  position: relative;
}
@media (max-width: 767px) {
  .about-image .about-shape {
    width: 294px;
    height: 294px;
  }
}
.about-image .about-shape::before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  -webkit-transform: scale(1.1) rotate(25deg);
  -moz-transform: scale(1.1) rotate(25deg);
  -ms-transform: scale(1.1) rotate(25deg);
  -o-transform: scale(1.1) rotate(25deg);
  transform: scale(1.1) rotate(25deg);
  border-radius: 50%;
  border: 2px solid #61009d;
  border-top-color: transparent;
  border-right-color: transparent;
}
.about-image .app {
  position: relative;
  max-width: 320px;
  position: absolute;
  top: 100px;
  left: 95px;
  -webkit-box-shadow: -15px 10px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -15px 10px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: -15px 10px 30px 0px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
}
@media (max-width: 767px) {
  .about-image .app {
    max-width: 190px;
    border-radius: 25px;
    top: 60px;
  }
}

.about-content .main-btn {
  margin-top: 40px;
}


.download-area .download-image {
  padding-top: 65px;
  padding-left: 60px;
  padding-bottom: 60px;
  position: relative;
}
@media (max-width: 767px) {
  .download-area .download-image {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.download-area .download-image .image {
  -webkit-box-shadow: 0px 3px 25px 0px rgba(8, 153, 231, 0.2);
  -moz-box-shadow: 0px 3px 25px 0px rgba(8, 153, 231, 0.2);
  box-shadow: 0px 3px 25px 0px rgba(8, 153, 231, 0.2);
  border-radius: 50px;
  max-width: 330px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .download-area .download-image .image {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .download-area .download-image .image {
    max-width: 295px;
  }
}
.download-area .download-image .download-shape-1 {
  width: 185px;
  height: 185px;
  background-color: #8200b7c7;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.download-area .download-image .download-shape-2 {
  position: absolute;
  bottom: -50px;
  right: 40px;
  z-index: -1;
}

.download-content .download-title {
  font-size: 1.7rem;
  text-align:start;
  font-weight: 600;
}
@media (max-width: 767px) {
  .download-content .download-title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .download-content .download-title {
    font-size: 28px;
  }
}

.download-content .text {
  margin-top: 35px;
  text-align:start;
}

.download-content ul {
  padding-top: 35px;
  text-align:start;
}
.download-content ul li {
  display: inline-block;
  margin-top: 15px;
}
.download-content ul li a {
  padding: 0 20px;
  height: 65px;
  border-radius: 5px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.download-content ul li a.app-store {
  background-color: #0898e7;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(8, 152, 231, 0.4);
  -moz-box-shadow: 0px 4px 10px 0px rgba(8, 152, 231, 0.4);
  box-shadow: 0px 4px 10px 0px rgba(8, 152, 231, 0.4);
  margin-right: 28px;
}
.download-content ul li a.app-store:hover {
  -webkit-box-shadow: 0px 4px 25px 0px rgba(8, 152, 231, 0.4);
  -moz-box-shadow: 0px 4px 25px 0px rgba(8, 152, 231, 0.4);
  box-shadow: 0px 4px 25px 0px rgba(8, 152, 231, 0.4);
}
.download-content ul li a.play-store {
  background-color: #525a63;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(82, 90, 99, 0.4);
  -moz-box-shadow: 0px 4px 10px 0px rgba(82, 90, 99, 0.4);
  box-shadow: 0px 4px 10px 0px rgba(82, 90, 99, 0.4);
}
.download-content ul li a.play-store:hover {
  -webkit-box-shadow: 0px 4px 25px 0px rgba(82, 90, 99, 0.4);
  -moz-box-shadow: 0px 4px 25px 0px rgba(82, 90, 99, 0.4);
  box-shadow: 0px 4px 25px 0px rgba(82, 90, 99, 0.4);
}


.footer-area {
  position: relative;
  z-index: 5;
  text-align:start;
}
.footer-area .footer-shape {
  position: absolute;
}
.footer-area .footer-shape.shape-1 {
  background: -webkit-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: -o-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: linear-gradient(#fe8464 0%, #fe6e9a 100%);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  left: 70px;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
@media (max-width: 767px) {
  .footer-area .footer-shape.shape-1 {
    left: auto;
    right: 40px;
    top: 25%;
  }
}
.footer-area .footer-shape.shape-2 {
  background: -webkit-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: -o-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: linear-gradient(#33c8c1 0%, #119bd2 100%);
  width: 39px;
  height: 39px;
  border-radius: 50%;
  bottom: 40px;
  left: 15%;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-shape.shape-2 {
    bottom: 85px;
  }
}
@media (max-width: 767px) {
  .footer-area .footer-shape.shape-2 {
    bottom: 110px;
  }
}
.footer-area .footer-shape.shape-3 {
  background: -webkit-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: -o-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: linear-gradient(#361cc1 0%, #2e82ef 100%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 35%;
  top: 40px;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-shape.shape-3 {
    top: 40%;
  }
}
.footer-area .footer-shape.shape-4 {
  background-color: #eba31a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 50%;
  bottom: 5px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
.footer-area .footer-shape.shape-5 {
  background-color: #e29e19;
  right: 37%;
  top: 0;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
.footer-area .footer-shape.shape-6 {
  background-color: #b60d81;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 125px;
  right: 15%;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
.footer-area .footer-shape.shape-7 {
  background-color: #6c63ff;
  width: 64px;
  height: 64px;
  bottom: 25px;
  right: 8%;
  opacity: 0.55;
  border-radius: 50%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-shape.shape-7 {
    bottom: 20%;
  }
}
@media (max-width: 767px) {
  .footer-area .footer-shape.shape-7 {
    bottom: 38%;
  }
}
.footer-area .footer-shape.shape-8 {
  top: 30px;
  right: -45px;
}
.footer-area .logo {
  max-width: 200px;
}

.footer-about {
  max-width: 320px;
}
.footer-about .text {
  margin-top: 0px;
}
.footer-about .social {
  margin-top: 30px;
}
.footer-about .social li {
  display: inline-block;
  margin-right: 25px;
}
.footer-about .social li a {
  font-size: 20px;
  color: #747e88;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-about .social li a:hover {
  color: #0898e7;
}

.footer-title .title {
  font-size: 20px;
  font-weight: 500;
}

.footer-link .link {
  padding-top: 10px;
}
.footer-link .link li {
  margin-top: 10px;
}
.footer-link .link li a {
  color: #747e88;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-link .link li a:hover {
  color: #0898e7;
}

.footer-link-wrapper {
  width: 50%;
}

.footer-contact .contact-list li {
  margin-top: 20px;
}
.footer-contact .contact-list li .contact-info .info-info {
  width: 30px;
}
.footer-contact .contact-list li .contact-info .info-content .text {
  color: #747e88;
  font-size: 16px;
}
.footer-contact .contact-list li .contact-info .info-content .text a {
  color: #747e88;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-contact .contact-list li .contact-info .info-content .text a:hover {
  color: #0898e7;
}

.footer-copyright .copyright {
  border-top: 2px solid #747e88;
  padding-top: 10px;
  padding-bottom: 25px;
}
.footer-copyright .copyright .copyright-text {
  padding-top: 15px;
}
.footer-copyright .copyright .copyright-text .text {
  color: #747e88;
}
.footer-copyright .copyright .copyright-privacy {
  padding-top: 15px;
}
.footer-copyright .copyright .copyright-privacy a {
  font-size: 16px;
  color: #747e88;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-copyright .copyright .copyright-privacy a:hover {
  color: #0898e7;
}

.select-menu {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: w80px;
  margin: auto;
  z-index: 10; 
  
}

.select-menu .select-btn{
  display: flex;
  height: 55px;
  background: #fff;
  padding: 20px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgb(135 135 135 / 85%);
  
}
.select-btn i{
  font-size: 25px;
  transition: 0.3s;
}
.select-menu.active .select-btn i{
  transform: rotate(-180deg);
}
.select-menu .options{
  position: relative;
  margin-top: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 5px rgb(135 135 135 / 85%);
  display: none;
}
.select-menu.active .options{
  display: block;
}
.options .option{
  display: flex;
  margin-top:3px;
  height: 55px;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
}
.options .option:hover{
  background: #F2F2F2;
  box-shadow: 0 0 5px rgb(135 135 135 / 85%);
}
.option i{
  font-size: 25px;
  margin-right: 12px;
}
.option .option-text{
  font-size: 18px;
  color: #333;
}


.back-to-top {
  font-size: 20px;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  background: #0898e7;
  background-size: 200%;
  text-align: center;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  display: none;
}
.back-to-top:hover {
  background-color: rgba(8, 152, 231, 0.5);
  color: #fff;
}


.single-features {
  text-align: start;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px 0 rgba(154, 154, 154, .16);
  -moz-box-shadow: 0 0 10px 0 rgba(154, 154, 154, .16);
  padding: 30px;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  -ms-transition: all .3s ease-out 0s;
  -o-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}
.single-features:hover {
  -webkit-box-shadow: 0 0 20px 0 rgb(0 0 0 / 16%);;
}
.single-features.features-color-1 .features-icon {
  background-color: #0898e7;
}
.single-features .features-icon {
  width: 60px;
  height: 60px;
  background-color: #0898e7;
  border-radius: 50%;
  text-align: center;
}
.single-features .features-content {
  padding-left: 25px;
}


.container-testi {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial {
  position: relative;
  max-width: 900px;
  width: 100%;
  padding: 50px 0;
  overflow: hidden;
}
.testimonial .image {
  height: 170px;
  width: 170px;
  object-fit: cover;
  border-radius: 50%;
}
.testimonial .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  height: 100%;
  width: 100%;
}
.slide p {
  text-align: center;
  padding: 0 160px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
.slide .quote-icon {
  font-size: 30px;
  color: #4070f4;
}
.slide .details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.details .name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.details .job {
  font-size: 12px;
  font-weight: 400;
  color: #333;
}

.nav-btn {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  transform: translateY(30px);
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.2s;
}
.nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.nav-btn::after,
.nav-btn::before {
  font-size: 20px !important;
  color: #fff;
}
.swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0.8);
}
.swiper-pagination-bullet-active {
  background-color: #4070f4;
}
@media screen and (max-width: 768px) {
  .slide p {
    padding: 0 20px;
  }
  .nav-btn {
    display: none;
  }
}

::selection{
  color: #fff;
  background: #007bff;
}
.wrapper{
  margin: 100px auto;
  max-width: 1100px;
}

.wrapper {
  margin: 50px auto; 
  max-width: 1100px;
}

.wrapper .filter {
  display: flex;
  justify-content: center;
}

.wrapper .items {
  display: flex;
  flex-wrap: wrap;
  max-width: 720px;
  width: 100%;
  justify-content: center; 
}

.items span {
  margin: 5px;
  padding: 7px 20px; 
  font-size: 16px; 
  font-weight: 500;
  cursor: pointer;
  color: #007bff;
  border-radius: 50px;
  border: 2px solid #007bff;
  transition: all 0.3s ease;
}

.items span.active,
.items span:hover {
  color: #fff;
  background: #007bff;
}


.gallery{
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.gallery .image{
  width: calc(100% / 4);
  padding: 7px;
  box-shadow: 0 0 3px rgba(0,0,0,0.1);

}
.gallery .image span{
  display: flex;
  width: 100%;
  overflow: hidden;
}
.gallery .image img{
  width: 100%;
  vertical-align: middle;
  transition: all 0.3s ease;
}
.gallery .image:hover img{
  transform: scale(1.1);
}
.gallery .image.hide{
  display: none;
}
.gallery .image.show{
  animation: animate 0.4s ease;
}
@keyframes animate {
  0%{
    transform: scale(0.5);
  }
  100%{
    transform: scale(1);
  }
}

.preview-box{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  background: #fff;
  max-width: 80vh;
  width: 100%;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  border-radius: 3px;
  padding: 0 5px 5px 5px;
  box-shadow: 0px 0px 15px rgb(118 112 112 / 49%);;
}
.preview-box.show{
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -42%) scale(1);
  transition: all 0.3s ease;
}
.preview-box .details{
  padding: 13px 15px 13px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.details .title{
  display: flex;
  font-size: 18px;
  font-weight: 400;
}
.details .title p{
  font-weight: 500;
  margin-left: 5px;
}
.details .icon{
  color: #007bff;
  font-style: 22px;
  cursor: pointer;
}
.preview-box .image-box{
  width: 100%;
  display: flex;
}
.image-box img{
  width: 100%;
  border-radius: 0 0 3px 3px;
}
.shadow{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: none;
  background: rgba(0,0,0,0.4);
}
.shadow.show{
  display: block;
}

@media (max-width: 1000px) {
  .gallery .image{
    width: calc(100% / 3);
  }
}
@media (max-width: 800px) {
  .gallery .image{
    width: calc(100% / 2);
  }
}
@media (max-width: 700px) {
  .wrapper .filter .items{
    max-width: 600px;
  }
  .filter .items span{
    padding: 7px 15px;
  }
}
@media (max-width: 600px) {
  .wrapper{
    margin: 30px auto;
  }
  .wrapper .filter .items{
    flex-wrap: wrap;
    justify-content: center;
  }
  .filter .items span{
    margin: 5px;
  }
  .gallery .image{
    width: 100%;
  }
}
